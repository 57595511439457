<template>
  <div class="idcardauth">
    <mt-header :title="$t('idcardauth')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <step-item :step="4" />
    <div class="content">
      <div class="item">
        <div class="title">{{ $t('idcardType') }}</div>
        <mt-field
          :placeholder="$t('idcardTypePlaceholder')"
          readonly
          :state="docTypeState"
          v-model="(Enum.docType.filter(e => e.code == form.docType)[0]||{}).name"
          @click.native="onPopupPicker(Enum.docType, (e) => {
            form.docType = e.code;
            form.idcard = '';
            BEHAVIOR_ADD({
              id: 'P05_C01_S_DOCTYPE',
              newValue: form.docType
            })
          })"
        >
          <mt-spinner v-if="Enum.docTypeLoading" type="snake" color="#F15234" :size="16"></mt-spinner>
          <i v-else class="iconfont">&#xe62a;</i>
        </mt-field>
      </div>
      <template v-if="form.docType">
        <div class="item">
          <div class="title">{{ $t('idcardNumber') }}</div>
          <mt-field
            v-if="form.docType == 'PY01'"
            :placeholder="$t('idcardNumberPlaceholder')"
            v-model="form.idcard"
            :state="idcardState"
            v-mask="'TTTTTTTTT'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P05_C02_I_IDCARD',
              newValue: form.idcard
            })"
          ></mt-field>
          <mt-field
            v-else-if="form.docType == 'PY03'"
            :placeholder="$t('idcardNumberPlaceholder')"
            v-model="form.idcard"
            :state="idcardState"
            v-mask="'#######'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P05_C02_I_IDCARD',
              newValue: form.idcard
            })"
          ></mt-field>
          <mt-field
            v-else-if="form.docType == 'PY04'"
            :placeholder="$t('idcardNumberPlaceholder')"
            v-model="form.idcard"
            :state="idcardState"
            v-mask="'#########000'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P05_C02_I_IDCARD',
              newValue: form.idcard
            })"
          ></mt-field>
          <mt-field
            v-else-if="form.docType == 'PY07'"
            :placeholder="$t('idcardNumberPlaceholder')"
            v-model="form.idcard"
            :state="idcardState"
            v-mask="'TTTTTTTTTTTTTTTTTTTTTTTT'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P05_C02_I_IDCARD',
              newValue: form.idcard
            })"
          ></mt-field>
          <mt-field
            v-else-if="form.docType == 'PY08'"
            :placeholder="$t('idcardNumberPlaceholder')"
            v-model="form.idcard"
            :state="idcardState"
            v-mask="'############'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P05_C02_I_IDCARD',
              newValue: form.idcard
            })"
          ></mt-field>
          <mt-field
            v-else-if="form.docType == 'PY09'"
            :placeholder="$t('idcardNumberPlaceholder')"
            v-model="form.idcard"
            :state="idcardState"
            v-mask="'TTTTTTTTTTTT'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P05_C02_I_IDCARD',
              newValue: form.idcard
            })"
          ></mt-field>
          <mt-field
            v-else-if="form.docType == 'PY10'"
            :placeholder="$t('idcardNumberPlaceholder')"
            v-model="form.idcard"
            :state="idcardState"
            v-mask="'TTTTTTTTTTTTTTT'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P05_C02_I_IDCARD',
              newValue: form.idcard
            })"
          ></mt-field>
          <mt-field
            v-else-if="form.docType == 'PY11'"
            :placeholder="$t('idcardNumberPlaceholder')"
            v-model="form.idcard"
            :state="idcardState"
            v-mask="'TTTTTTTTTTTTTTTTT'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P05_C02_I_IDCARD',
              newValue: form.idcard
            })"
          ></mt-field>
          <mt-field
            v-else-if="form.docType == 'PY05'"
            :placeholder="$t('idcardNumberPlaceholder')"
            v-model="form.idcard"
            :state="idcardState"
            v-mask="'##-#######-#'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P05_C02_I_IDCARD',
              newValue: form.idcard
            })"
          ></mt-field>
          <mt-field
            v-else-if="form.docType == 'PY06'"
            :placeholder="$t('idcardNumberPlaceholder')"
            v-model="form.idcard"
            :state="idcardState"
            v-mask="'CRN-###########-#'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P05_C02_I_IDCARD',
              newValue: form.idcard
            })"
          ></mt-field>
          <mt-field
            v-else-if="form.docType == 'PY02'"
            :placeholder="$t('idcardNumberPlaceholder')"
            v-model="form.idcard"
            :state="idcardState"
            v-mask="'A-##-#######-#'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P05_C02_I_IDCARD',
              newValue: form.idcard
            })"
          ></mt-field>
          <mt-field
            v-else
            :placeholder="$t('idcardNumberPlaceholder')"
            v-model="form.idcard"
            :state="idcardState"
            v-mask="'TTTTTTTTTTTTTTTTTT'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P05_C02_I_IDCARD',
              newValue: form.idcard
            })"
          ></mt-field>
        </div>
      </template>
      <upload-image ref="uploadImage" v-model="images" behavior="P05" />
    </div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit">{{
        $t("nextStep")
      }}</mt-button>
    </div>
    <popup-picker ref="popupPicker" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { emptyRules, idcardRules } from "../../utils/rules";
import { applyStep } from '../../utils/api'
import popupPicker from '@/components/popupPicker.vue'
import callPhone from '@/components/callPhone.vue'
import stepItem from '../../components/stepItem.vue'
import uploadImage from './UploadImage.vue'
import branch from '../../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../../utils/gtm.js'
import mixin from './mixin'
export default {
  name: 'Idcardauth',
  mixins: [mixin],
  components: {
    uploadImage,
    popupPicker,
    callPhone,
    stepItem,
  },
  data() {
    return {
      isCheck: false,
      form: {
        docType: '',
        idcard: '',
      },
      images: [],
    }
  },
  computed: {
    ...mapState(["apply", "user"]),
    docTypeState() {
      this.onStorageItem('docType')
      return this.isCheck ? emptyRules(this.form.docType) : null;
    },
    idcardState() {
      this.onStorageItem('idcard')
      return this.isCheck ? idcardRules(this.form.idcard, this.form.docType) : null;
    },
  },
  created() {
    gtmPageView(this)
    Object.keys(this.form).forEach((val) => {
      this.form[val] = this.apply[val] || ''
    })
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P05_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P05_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    init() {
      this.GET_DOC_TYPE() // 获取单据类型
    },
    // 校验输入内容
    validation(list) {
      if (list.indexOf('docType') >= 0 && this.docTypeState != "success") {
        return this.$t('idcardTypeError')
      }
      if (list.indexOf('idcard') >= 0 && this.idcardState != "success") {
        return this.idcardState == "warning"
          ? this.$t('idcardNumberPlaceholder')
          : this.$t('idcardNumberError')
      }
    },
    // 提交下一步
    async submit() {
      this.isCheck = true;
      var error = this.validation([
        'docType',
        'idcard'
      ]);
      var uploadImageError = this.$refs.uploadImage.validation()
      if (error) return this.$toast(error);
      if (uploadImageError) return this.$toast(uploadImageError)
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('05_IDCARD_AUTH', { content_name: '05_IDCARD_AUTH' })
        }
        // Google Analytics  IDCARD_AUTH
        if (localStorage['ga']) {
          window.gtag('event', 'IDCARD_AUTH')
        }
      } catch(e) {
        console.log(e)
      }
      var idcard = this.form.idcard.replace(/[^a-zA-Z0-9]/g, '');
      if (this.form.docType == 'PY06') {
          idcard = idcard.replace(/[^0-9]/g, '');
      }
      let data = {
        currentStep: 2,
        totalSteps: 8,

        applyId: this.user.applyId,

        ...this.form,
        idcard: idcard,
        images: this.images.map(num => ({
          imageId: num
        }))
      }
      await this.$axios({
        method: "post",
        url: applyStep,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              applyId: e.body.applyId
            })
            if (e.body.name) {
              this.SET_USER({
                ...this.user,
                name: e.body.name
              })
            }
            this.$router.push('/step/realnameauth')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done()
      branch.logEvent('05_IDCARD_AUTH', { metadata: data })
    }
  }
}
</script>

<style lang="scss" scoped>
.idcardauth {
  background-color: #FBFBFF;
  .content {
    margin: 10px;
    > .title {
      font-size: 14px;
      color: #F15234;
      padding: 0px 20px;
    }
    .item {
      margin-top: 20px;
      .mint-cell {
        background: white;
        /deep/ .mint-cell-wrapper {
          background: none;
        }
      }
      .title {
        margin: 6px 15px;
        font-size: 16px;
        color: #1D1D1D;
        &::after {
          content: '*';
          font-size: 14px;
          vertical-align: top;
          color: #E92121;
        }
      }
      .mint-field {
        border-radius: 6px;
        min-height: 46px;
      }
    }
  }
  .iconfont {
    margin: 0 5px;
    color: #F15234;
  }
  .bottom {
    padding: 20px 20px 40px 20px;
    .mint-button {
      border-radius: 6px;
    }
  }
}
</style>